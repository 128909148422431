import {Dialog, DialogActions, DialogContentText, DialogTitle, Grid, Typography} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import React from "react";
import {TermsDialogTypes} from "./types";

export const TermsDialogEnglish = (props: TermsDialogTypes) => {
    return (
        <Dialog
            open={props.show}
            onClose={props.onClose}>
            <DialogTitle>
                General Terms and Conditions for Star Ocean ApS as organizer of charters for M/S Ostsee Star
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Typography>
                                Advokatfirmaet Espersen<br />
                                Tordenskjoldsgade 6<br />
                                9900 Frederikshavn<br />
                                Tlf. 98 43 34 11
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                1201-1852 - LE/HA<br />
                                30 November 2022
                            </Typography>
                            </Grid>
                    </Grid>

                    <Typography>
                        <br /><b>General Conditions:</b>
                    </Typography>
                    <Typography>
                        1. Present General Terms and Conditions apply to any and all agreements of charter concluded by
                        Star Ocean ApS, hereinafter ‘Star Ocean’, regardless of whether a document regarding the
                        agreement is drafted or not. In addition to these General Terms and Conditions the rules posted
                        on
                        the vessel apply.<br />
                    </Typography>
                    <Typography>
                        2. When booking a charter tour participants enter into a binding agreement with Star Ocean as
                        organizer of charters for M/S Ostsee Star. For security reasons participants are obliged to
                        submit a
                        list of names of all participants.<br />
                    </Typography>
                    <Typography>
                        3. All passengers participate in the charter tour at their own risk and responsibility and are
                        during
                        voyage considered part of the crew and are as such under the direction of the captain and
                        subject
                        to applicable navigational authority regulations and the Danish Maritime Law, hereunder in
                        particular regarding the Captain’s command of the vessel. All participants are obliged to take
                        part in
                        regular routines and activities on board, in all weather conditions. In emergencies participants
                        are
                        obliged to cooperate with the crew to avoid or mitigate damage.<br />
                    </Typography>
                    <Typography>
                        4. For all passengers aged 18-65 a fishing permit is mandatory in Denmark.
                    </Typography>
                    <Typography>
                        <br /> <b>Liability:</b>
                    </Typography>
                    <Typography>
                        5. Star Ocean is not liable for any loss, direct or indirect, hereunder loss of income, with the
                        exception
                        of what is dictated by mandatory rules of the Danish Maritime Law or other applicable mandatory
                        law.
                    </Typography>
                    <Typography>
                        6. Star Ocean’s liability is limited to the fullest possible extent, in accordance with rules of
                        the Danish
                        Maritime Law.
                    </Typography>
                    <Typography>7. Disputes shall be settled in accordance with Danish Law at the Court in
                        Hjørring unless mandatory
                        rules in the Danish Maritime Law or other mandatory Law dictate a different venue.
                    </Typography>
                    <Typography> 8. Star Ocean is not liable for lost valuables.
                    </Typography>
                    <Typography> 9. Star Ocean is obliged to compensate for losses of luggage being damaged or
                        lost caused by an
                        event during the charter tour only if the loss is due to serious error or gross negligence by
                        Star
                        Ocean or by somebody employed by Star Ocean.
                    </Typography>
                    <Typography> 10. Star Ocean shall compensate for loss arising from the death or injury of
                        a passenger caused by an
                        event during the charter tour in accordance with EU- Regulation 392/2009.
                    </Typography>
                    <Typography> 11. Star Ocean is obliged to compensate for loss or damage arising from
                        delay, only if said delay is
                        caused by serious error or gross negligence on the part of Star Ocean.
                    </Typography>
                    <Typography> 12. Star Ocean is entitled to have the voyage carried out by an alternative
                        charterer. In the event that
                        an alternative charterer is carrying out a certain part of the charter tour, Star Ocean is not
                        liable for
                        any loss, damage or delay that may occur during that part of the charter tour being operated by
                        an
                        alternative charterer.
                    </Typography>
                    <Typography> 13. Any complaints regarding conditions on board the vessel must be directed
                        at the Captain. Claims
                        regarding the charter tour should be addressed to Star Ocean, by e-mail.
                    </Typography>
                    <Typography> <br /> <b>Prices:</b></Typography>
                    <Typography> 14. Deposit is 15 % of the price of the charter tour, unless otherwise
                        agreed, and is to be paid
                        immediately upon booking. The balance must be paid 4 weeks before departure. Invoices for
                        deposit as well as remainder of the sum must be paid no later than on the due date set out in
                        the
                        invoice. Booking is final when deposit is paid.
                    </Typography>
                    <Typography> 15. The price for daytrips does not include overnight accommodation. This may
                        be arranged, at
                        additional charges.
                    </Typography>
                    <Typography><br /><b>Cancellations:</b></Typography>
                    <Typography> 16. Cancellation fees amount to EUR 25,00 per participant. At cancellation a
                        percentage of the cost of
                        the charter tour will be charged, depending on the time of cancellation:
                    </Typography>
                    <Typography> 16.1 When cancelling until 50 days before departure only 25 % of the tour
                        price will be charged.
                    </Typography>
                    <Typography> 16.2 When cancelling 49-30 days before departure only 50 % of the tour price
                        will be charged.
                    </Typography>
                    <Typography> 16.3 When cancelling 29-15 days before departure only 80 % of the tour price
                        will be charged.
                    </Typography>
                    <Typography> 16.4 When cancelling 14 days or less before departure 100 % of the tour price
                        will be charged and no
                        reduction will be extended. Hence, it is recommended to acquire a travel insurance covering
                        cancellation due to illness, weather conditions or any other situation preventing the charter
                        tour
                        taking place.
                    </Typography>
                    <Typography> 17. Star Ocean reserves the right to – prior to the date of departure –
                        adjust such prices, which may
                        demand adjusting due to significant variations in transport costs or currency exchange rates on
                        which the calculation / payment of the tour price may have been based. Should the tour price
                        total
                        thus be increased by more than 10 %, the participant is entitled to cancel the charter tour and
                        receive a refund of amounts already paid. Such refund is provided, however, that Star Ocean
                        receives notice of cancellation immediately – and no later than 3 days – after informing about
                        the
                        price adjustment.
                    </Typography>
                    <Typography> <br /> <b>Special Conditions:</b> </Typography>

                    <Typography> 18. Narcotic drugs are not permitted on board. Nor is it permitted to bring
                        own drinks on board. Non-
                        compliance with these prohibitions may result in a cancellation of the charter tour, whether
                        prior

                        to or following the outset of the tour. For charter tours cancelled because of non-compliance
                        with
                        prohibitions there will be no refund, and no rebooking will be possible.
                    </Typography>
                    <Typography> 19. Star Ocean reserves the right to cancel the charter tour due to Force
                        Majeure, weather conditions,
                        operational problems, sudden illness / pandemics, or in order to ensure the safety of the
                        participants. Should such situation arise, the participants will be informed as soon as Star
                        Ocean
                        becomes aware of the same situation.
                    </Typography>
                    <Typography> 20. Should Star Ocean cancel the charter tour due to any of the situations
                        listed in clause 19,
                        participants will be offered rebooking. Should any of the situations listed in clause 19 occur
                        following the outset of the tour, the Captain shall decide whether seeking nearest port is
                        advisable.
                        If more than half of the charter tour has been completed, no rebooking will be offered.
                    </Typography>
                    <Typography> 21. Star Ocean is not liable for any costs that participants may incur as a
                        result of cancellation pursuant
                        to clauses 19 and 20, and no further claims can be made against Star Ocean in this respect.
                    </Typography>
                    <Typography> 22. Star Ocean reserves the right to make adjustments of the tour, hereunder
                        regarding the planned time
                        of departure, if sudden external events such as – but not limited to – changes in weather
                        forecast
                        and wind speed, engine failure, strikes or lockouts, illness etc. necessitates it.
                    </Typography>
                    <Typography> 23. Participants are obliged to arrive at the M/S Ostsee Star well before
                        departure. At late arrivals, where
                        consideration to other participants has necessitated that the charter tour sets out, the right
                        to
                        participation and / or a refund of the tour price is forfeited.
                    </Typography>
                    <Typography> 24. Any shore excursions made after the outset of the charter tour,
                        regardless of purpose, shall be on
                        the participants’ own risk and responsibility.
                    </Typography>
                    <Typography> 25. The participants are liable for damage to property of the M/S Ostsee
                        Star, whether by accident or
                        intentionally. Ravage / vandalism as well as theft and violence will be reported to the police
                        and
                        damages will be claimed.
                    </Typography>
                    <Typography> 26. The Captain is entitled to put off participants, whom he finds unfit to
                        participate in the charter tour or
                        who are offensive to other participants, whether due to illness, intoxication, consumption of
                        narcotic
                        drugs, repeated non-compliance with the safety regulations and codes of conduct on board. In
                        such
                        event no refund will be made and no rebooking will be possible.</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}