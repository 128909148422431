import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import daLocale from '@fullcalendar/core/locales/da';
import deLocale from '@fullcalendar/core/locales/de';
import enLocale from '@fullcalendar/core/locales/en-gb';
import React from 'react';
import "./App.scss";
import {APIContext} from "../contexts";
import {useSearchParams} from "react-router-dom";
import {AddBookingDialog} from "../components";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import {TripEvent} from "../models/TripEvent";
import {Booking} from "../models/Booking";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {Settings} from "luxon";
import {getTitle} from "../utils/getTitle";
import {QuestionMarkOutlined} from "@mui/icons-material";

export const App = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const {t} = useTranslation();

  const {getEvents, addBooking} = React.useContext(APIContext);
  const [events, setEvents] = React.useState<any>([]);
  const [language, setLanguage] = React.useState<typeof daLocale | typeof deLocale | typeof enLocale>(daLocale);
  const [currentEvent, setCurrentEvent] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = React.useState<boolean>(false);
  const [totalTripPrice, setTotalTripPrice] = React.useState<string | undefined>();
  const [showColorExplanationDialog, setShowColorExplanationDialog] = React.useState<boolean>(false);

  React.useEffect(() => {
    const language = searchParams.get("lang");

    if (language) {
      void i18n.changeLanguage(language);
      Settings.defaultLocale = language;
    } else {
      void i18n.changeLanguage("en-US");
      Settings.defaultLocale = "en-US";
    }

    switch (language) {
      case "da-DK":
        setLanguage(daLocale)
        break;
      case "de-DE":
        setLanguage(deLocale)
        break;
      default:
      case "en-US":
        setLanguage(enLocale)
        break;
    }
  }, [searchParams])

  React.useEffect(() => {
    fetchEvents();
  }, [])

  const fetchEvents = (): void => {
    getEvents().then(events => {
      const convertedEvents: any[] = [];
      events.forEach((event: any) => {
        const isBeforeToday: boolean = new Date(event.startDate) < new Date();
        const isFullyBooked: boolean = event.seatsAvailable === 0;
        const isPartiallyBooked: boolean = ((event.bookedSeats / event.seatsAvailable) * 100) > 50;
        const isAnnulled: boolean = (event.deletedAt !== null && event.deletedAt !== undefined && event.deletedAt > 0);

        let className = "";
        if (isAnnulled) {
          className = "event--annulled";
        } else if (isBeforeToday) {
          className = "event--disabled";
        } else if (isFullyBooked) {
          className = "event--fully-booked";
        } else if (isPartiallyBooked) {
          className = "event--partially-booked";
        } else {
          className = "event--enabled"
        }

        convertedEvents.push({
          title: getTitle(searchParams.get("lang"), event, t),
          start: event.startDate,
          end: event.endDate,
          event: event,
          classNames: [className]
        })
      })

      setEvents(convertedEvents);
    });
  }

  return (
    <>
      <CssBaseline/>

      <Box component="div" display={{xs: 'none', md: 'block'}}>
        <div className={"color-explanation"}>
          <div>
            <Typography>
              <div className={"box box--grey"}/>
              <span>{t('ongoing-trip')}</span></Typography>
            <Typography>
              <div className={"box box--yellow"}/>
              <span>{t('almost-fully-booked')}</span></Typography>
          </div>
          <div>
            <Typography>
              <div className={"box box--red"}/>
              <span>{t('fully-booked')}</span></Typography>
            <Typography>
              <div className={"box box--blue"}/>
              <span>{t('still-seats')}</span></Typography>
          </div>
          <div>
            <Typography>
              <div className={"box box--grey-striped"}/>
              <span>{t('cancelled-trip')}</span></Typography>
          </div>
        </div>
      </Box>

      <Dialog
        onClose={() => {
          setShowColorExplanationDialog(false)
        }}
        open={showColorExplanationDialog}>
        <DialogContent>
        <div className={"color-explanation color-explanation--relative"}>
          <div>
            <Typography>
              <div className={"box box--grey"}/>
              <span>{t('ongoing-trip')}</span></Typography>
            <Typography>
              <div className={"box box--yellow"}/>
              <span>{t('almost-fully-booked')}</span></Typography>
          </div>
          <div>
            <Typography>
              <div className={"box box--red"}/>
              <span>{t('fully-booked')}</span></Typography>
            <Typography>
              <div className={"box box--blue"}/>
              <span>{t('still-seats')}</span></Typography>
          </div>
          <div>
            <Typography>
              <div className={"box box--grey-striped"}/>
              <span>{t('cancelled-trip')}</span></Typography>
          </div>
        </div>
        </DialogContent>
      </Dialog>

      <FullCalendar
        eventClick={(info: any) => {
          const currentEvent: TripEvent = info.event._def.extendedProps.event;

          const isEventStartAfterNow: boolean = new Date(currentEvent.startDate) > new Date();
          const isFullyBooked: boolean = currentEvent.seatsAvailable === 0;
          const isAnnulled: boolean = (currentEvent.deletedAt !== null && currentEvent.deletedAt !== undefined && currentEvent.deletedAt > 0);

          if (isEventStartAfterNow && !isFullyBooked && !isAnnulled) {
            setCurrentEvent(info.event._def.extendedProps.event)
          }
        }}
        height={"100vh"}
        locale={language}
        nowIndicator={true}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={events}
      />

      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={loading}>
        <CircularProgress color="inherit"/>
      </Backdrop>

      <Dialog
        open={showSuccessDialog}
        onClose={() => {
          setShowSuccessDialog(false);
          setTotalTripPrice(undefined);
        }}>
        <DialogTitle>
          {t('success')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Typography>
                  {t('trip-received-text')}
                </Typography>
              </Grid>

              <Grid item={true} xs={12}>
                <Typography>
                  {t('total-cost-text')}<b>{totalTripPrice}</b>
                </Typography>
              </Grid>

              <Grid item={true} xs={12}>
                <Typography>
                  {t('trip-received-text-p2')}
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setShowSuccessDialog(false);
            setTotalTripPrice(undefined);
          }} autoFocus>
            {t('trip-received-text-button')}
          </Button>
        </DialogActions>
      </Dialog>

      {/*
      // TODO: add another state for show, such that event isn't set to null and content isn't jumping on callbacks
      */}
      <AddBookingDialog
        loading={false}
        event={currentEvent}
        onCancel={() => {
          setCurrentEvent(null);
        }}
        onApply={async (booking: Booking) => {
          const totalTripPriceInDkk: string = booking.seats * (currentEvent.priceInDkk) + " kr.";
          const totalTripPriceInEur: string = booking.seats * (currentEvent.priceInEur) + " €.";

          setCurrentEvent(null);
          setLoading(true);

            booking.lastName.trim();
            booking.firstName.trim();
            booking.phone.trim();
            booking.email.trim();
            booking.language = searchParams.get("lang");

            await addBooking(booking);
          await fetchEvents();

          setTimeout(() => {
            setLoading(false);
            setShowSuccessDialog(true);

            if (searchParams.get("lang") === "da-DK") {
              setTotalTripPrice(totalTripPriceInDkk);
            } else {
              setTotalTripPrice(totalTripPriceInEur);
            }
          }, 1500)
        }}
        show={currentEvent !== null && currentEvent !== undefined}/>

      <Box
        style={{position: "absolute", bottom: "24px", right: "24px"}}
        component="div"
        display={{xs: 'block', md: 'none'}}>
        <Fab
          onClick={() => {
            setShowColorExplanationDialog(true);
          }}
          color="primary">
          <QuestionMarkOutlined/>
        </Fab>
      </Box>
    </>
  );
}