import {Dialog, DialogActions, DialogContentText, DialogTitle, Grid, Typography} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import React from "react";
import {TermsDialogTypes} from "./types";

export const TermsDialogDanish = (props: TermsDialogTypes) => {
  return (
    <Dialog
      open={props.show}
      onClose={props.onClose}>
      <DialogTitle>
        Almindelige betingelser for Star Ocean ApS
        som arrangør af charterture med M/S Ostsee Star
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography>
                Advokatfirmaet Espersen<br />
                Tordenskjoldsgade 6<br />
                9900 Frederikshavn<br />
                Tlf. 98 43 34 11
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                1201-1852 - LE/HA<br />
                25.Oktober 2023
              </Typography>
            </Grid>
          </Grid>

          <Typography>
            <br /><b>Generelle Betingelser:</b>
          </Typography>
          <Typography>
            1. Nærværende almindelige betingelser er gældende for enhver aftale om befordring indgået af Star
            Ocean ApS, i det følgende kaldet Star Ocean, uanset om der udstedes dokument om aftalen eller
            ej. I tillæg hertil gælder de på fartøjet opslåede regler.<br />
          </Typography>
          <Typography>
            2. Med booking af chartertur indgås en bindende aftale mellem de deltagende og Star Ocean ApS
            som arrangør for M/S Ostsee Star. Af sikkerhedsmæssige årsager er deltagerne forpligtet til at
            aflevere en navneliste over samtlige deltagere.<br />
          </Typography>
          <Typography>
            3. Alle passagerer deltager i charterturen på eget ansvar og sidestilles under sejlads med fartøjets
            besætning under ledelse af kaptajnen, og underlægger sig dermed de gældende søfartsregler og
            den danske sølov, herunder i særdeleshed for så vidt angår kaptajnens kommando af fartøjet. Alle
            deltagende forpligter sig til at tage del i almindelige skibsrutiner og aktiviteter i al slags vejr. I
            nødstilfælde forpligter de deltagende sig til at samarbejde med besætningen for at undgå eller
            begrænse eventuelle skader.<br />
          </Typography>
          <Typography>
            4. For alle passagerer i alderen 18-65 er der i Danmark krav om fisketegn.
          </Typography>
          <Typography>
            <br /> <b>Ansvarsbegrænsning:</b>
          </Typography>
          <Typography>
            5. Star Ocean kan ikke gøres ansvarlig for tab, direkte eller indirekte, herunder tab af indtægter ud
            over, hvad ufravigelige regler i den danske sølov eller anden ufravigelig lovgivning foreskriver.
          </Typography>
          <Typography>
            6. Star Ocean begrænser sit ansvar mest muligt i henhold til den danske sølovs regler.
          </Typography>
          <Typography>7. Medmindre ufravigelige regler i den danske sølov eller anden ufravigelig lovgivning hjemler andet
            værneting, skal alle tvister med Star Ocean afgøres efter dansk lovgivning ved Star Ocean’s
            hjemting.
          </Typography>
          <Typography> 8. Star Ocean er ikke ansvarlig for tab af værdigenstande.
          </Typography>
          <Typography>9. Star Ocean er alene forpligtet til at erstatte tab, som forvoldes ved at rejsegods går tabt eller
            beskadiges på grund af en hændelse under befordringen, såfremt tabet eller skaden er forvoldt ved
            grov fejl eller grov forsømmelse af Star Ocean selv eller nogen, Star Ocean svarer for.
          </Typography>
          <Typography> 10. Star Ocean erstatter tab, som forvoldes ved, at en passager dør eller kommer til skade på grund af
            en hændelse under befordringen i overensstemmelse med bestemmelserne i EF-Forordning
            392/2009.
          </Typography>
          <Typography>11. Star Ocean er alene forpligtet til at erstatte tab eller skade forvoldt ved forsinkelse, som skyldes
            grov fejl eller grov forsømmelse af Star Ocean.
          </Typography>
          <Typography>12. Star Ocean er berettiget til at lade charterturen eller en del heraf udføre af et andet rederi. Skal en
            bestemt del af turen udføres af et andet rederi, er Star Ocean ansvarsfri for bortkomst,
            beskadigelse eller forsinkelse, der indtræffer under den del af rejsen, som udføres af det andet
            rederi.
          </Typography>
          <Typography> 13. Evt. reklamation over forholdene om bord skal rettes til kaptajnen. Reklamation over charterturen
            skal rettes til Star Ocean via mail.
          </Typography>
          <Typography> <br /> <b>Priser:  </b></Typography>
          <Typography> 14. Depositum beregnes som 15 % af charterturens pris, med mindre andet er aftalt, og betales straks
            efter booking. Restbeløbet betales 4 uger før afgang. Fakturaer på såvel depositum som restbeløbet
            betales senest på forfaldsdatoen, som fremgår af fakturaen. Med betaling af depositum er booking
            endelig.
          </Typography>
          <Typography> 15. Ved éndagsture er overnatning ikke inkluderet i turprisen, men kan evt. aftales særskilt mod
            ekstrabetaling.
          </Typography>
          <Typography><br/><b>Afbestilling:</b></Typography>
          <Typography>16. Afbestillingsgebyr udgør EUR 25,00 pr. deltager. Ved afbestilling betales en procentdel af turprisen,
            afhængigt af tidspunktet for afbestilling:
          </Typography>
          <Typography> 16.1 Ved afbestilling indtil 50 dage før afgang betales alene 25 % af turprisen.
          </Typography>
          <Typography> 16.2 Ved afbestilling 49-30 dage før afgang betales alene 50 % af turprisen.
          </Typography>
          <Typography> 16.3 Ved afbestilling 29-15 dage før afgang betales alene 80 % af turprisen.
          </Typography>
          <Typography> 16.4 Ved afbestilling 14 dage eller mindre før afgang betales 100 % af turprisen, og der ydes ingen
            reduktion. Det anbefales derfor, at man tegner en rejseforsikring, således at man er dækket i
            tilfælde af afbestilling / aflysning af rejsen pga. sygdom, vejrlig eller andet, der gør at turen ikke kan
            gennemføres.
          </Typography>
          <Typography> 17. Star Ocean forbeholder sig ret til, inden afrejsedagen, at foretage prisændringer som skyldes
            væsentlige ændringer i transportomkostninger eller valutakurser, som måtte være anvendt til
            beregning / ved betaling af turprisen. Hvis den samlede turpris således forhøjes med mere end 10
            %, er deltageren berettiget til at annullere rejsen og få det indbetalte beløb refunderet. En
            betingelse for refusion er, at annullering meddeles Star Ocean straks - og ikke senere end 3 dage -
            efter at prisforhøjelsen er varslet.
          </Typography>
          <Typography> <br /> <b>Særlige Forhold:</b> </Typography>
          <Typography>18. Euforiserende eller øvrige narkotiske stoffer er ikke tilladt om bord. Det er heller ikke tilladt at
            medbringe egne drikkevarer om bord. Overtrædelse af disse forbud kan medføre aflysning af
            charterturen, det være sig inden eller efter påbegyndt chartertur. Charterture, der aflyses som
            følge af overtrædelse af forbud, vil ikke blive refunderet og kan ikke ombookes.
          </Typography>
          <Typography> 19. Star Ocean forbeholder sig ret til at aflyse charterturen pga. Force Majeure, vejrlig, driftsproblemer,
            akut sygdom / pandemier, eller af hensyn til deltagernes sikkerhed. Måtte en sådan situation opstå,
            vil deltagerne blive varslet herom, straks Star Ocean får kendskab til den opståede situation.
          </Typography>
          <Typography> 20. Hvis Star Ocean aflyser charterturen pga. en af de i pkt. 19 listede begivenheder, vil de deltagende
            blive tilbudt en ombooking. Hvis en af de i pkt. 19 listede begivenheder indtræffer efter påbegyndt
            chartertur, vil kaptajnen afgøre, hvorvidt det er nødvendigt at søge nærmeste havn. En ombooking
            vil ikke blive tilbudt, hvis mindst halvdelen af charterturen er gennemført.
          </Typography>
          <Typography>21. Star Ocean er ikke ansvarlig for eventuelle omkostninger, som deltagerne måtte afholde som følge
            af aflysning i medfør af punkterne 19 og 20, og ingen yderligere krav kan gøres gældende mod Star
            Ocean.
          </Typography>
          <Typography> 22. Star Ocean forbeholder sig retten til at foretage ændringer i turen, herunder det planlagte
            afgangstidspunkt, nødvendiggjort af pludseligt opståede, udefrakommende begivenheder, såsom –
            men ikke begrænset til – ændringer i vejr og vindstyrke, maskinskade, strejker, sygdom m.v.
          </Typography>
          <Typography>23. Deltagerne forpligter sig til at ankomme til M/S Ostsee Star i god tid inden afgang. Ved for sen
            ankomst, hvor charterturen af hensyn til øvrige passagerer er påbegyndt, mistes retten til deltagelse
            og / eller refusion af turprisen.
          </Typography>
          <Typography> 24. Eventuelle udflugter i land efter charterturens påbegyndelse, uanset formål, foretager deltagerne på
            eget ansvar og risiko.
          </Typography>
          <Typography>25. Deltagerne er erstatningspligtige for tingskade forvoldt på M/S Ostsee Star, det være sig ved uheld
            eller forsætligt. Hærværk / vandalisme samt tyveri og vold vil blive politianmeldt og erstatning vil blive
            krævet.
          </Typography>
          <Typography>26. Kaptajnen har ret til at afsætte deltagere, som på grund af sygdom, beruselse, indtagelse af
            narkotiske stoffer, gentagne overtrædelser af ordens- og sikkerhedsreglerne om bord, eller andre
            årsager efter hans skøn er enten uegnede til at deltage i charterturen eller til gene for de øvrige
            deltagere. I sådant tilfælde ydes ingen refusion og ombooking vil ikke blive tilbudt.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} autoFocus>
          LUK
        </Button>
      </DialogActions>
    </Dialog>
  );
}