import {Dialog, DialogActions, DialogContentText, DialogTitle, Grid, Typography} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import React from "react";
import {TermsDialogTypes} from "./types";

export const TermsDialogGerman = (props: TermsDialogTypes) => {
  return (
    <Dialog
      open={props.show}
      onClose={props.onClose}>
      <DialogTitle>
        Allgemeine Geschäftsbedingungen der Star Ocean
        2016 ApS als Veranstalter von Chartertouren mit
        der M/S Ostsee Star
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography>
                Advokatfirmaet Espersen<br />
                Tordenskjoldsgade 6<br />
                9900 Frederikshavn<br />
                Tlf. 98 43 34 11
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                1201-1852 - LE/HA<br />
                30 November 2022
              </Typography>
            </Grid>
          </Grid>

          <Typography>
            <br /><b>Allgemeine Bedingungen:</b>
          </Typography>
          <Typography>
            1. Diese Allgemeinen Bedingungen gelten für jeden von Star Ocean ApS (nachstehend "Star

            Ocean") abgeschlossenen Beförderungsvertrag, unabhängig davon, ob ein Vertragsdokument aus-
            gestellt wird oder nicht. Darüber hinaus gelten die an Bord des Schiffes ausgehängten Regeln.<br />
          </Typography>
          <Typography>
            2. Mit der Buchung der Chartertour kommt ein Vertrag zustande zwischen den Teilnehmern und der
            Star Ocean ApS als Veranstalter. Aus Sicherheitsgründen müssen die Teilnehmer eine Liste mit
            den Namen aller Teilnehmer abgeben.<br />
          </Typography>
          <Typography>
            3. Alle Passagiere nehmen auf eigenes Risiko an der Chartertour teil und können während der Fahrt

            mit Besatzungsmitgliedern verglichen werden. Sie unterliegen somit den geltenden Schifffahrtsvor-
            schriften und dem dänischen Seehandelsgesetz, insbesondere was die Schiffsführung durch den

            Kapitän betrifft. Alle Teilnehmer verpflichten sich zur aktiven Mitarbeit bei den normalen Arbeiten

            an Bord – und das bei jedem Wetter. Im Falle eines Notfalls verpflichten sich die Teilnehmer zur Zu-
            sammenarbeit mit der Besatzung, um Schaden abzuwenden oder zu begrenzen.<br />
          </Typography>
          <Typography>
            4. Für alle Passagiere zwischen 18 und 65 Jahren sind in Dänemark Angelscheine erforderlich.
          </Typography>
          <Typography>
            <br /> <b>Begrenzung der Haftung:</b>
          </Typography>
          <Typography>
            5. Für unmittelbare oder mittelbare Verluste, insbesondere Einnahmeverlust, kann Star Ocean nicht
            über die zwingenden Vorschriften des dänischen Seehandelsgesetzes oder andere zwingende Vor-
            schriften hinaus haftbar gemacht werden.
          </Typography>
          <Typography>
            6. Star Ocean beschränkt seine Haftung so weit wie nach den Vorschriften des dänischen Seehandels-
            gesetzes zulässig.
          </Typography>
          <Typography>7. Sofern nicht zwingende Vorschriften des dänischen Seehandelsgesetzes oder andere zwingende
            Rechtsvorschriften einen anderen Gerichtsstand vorsehen, sind alle Streitigkeiten mit Star Ocean
            nach dänischem Recht am Sitz von Star Ocean zu entscheiden.
          </Typography>
          <Typography> 8. Star Ocean haftet nicht für abhandengekommene Wertsachen.
          </Typography>
          <Typography>9. Schäden durch unterwegs verlorengegangenes oder beschädigtes Reisegepäck werden von Star
            Ocean nur dann ersetzt, wenn der Schaden durch grobe Fehler oder grobe Fahrlässigkeit seitens
            Star Oceans oder einer Person, für die Star Ocean verantwortlich ist, verursacht wurde.
          </Typography>
          <Typography> 10. Bei Tod oder Körperverletzung eines Passagiers aufgrund eines Ereignisses während der Fahrt haf-
            tet Star Ocean nach Maßgabe der Verordnung (EG) Nr. 392/2009 für den Schaden.
          </Typography>
          <Typography>11. Star Ocean hat nur solche durch Verspätung verursachte Schäden zu ersetzen, die auf grobe Fehler
            oder grobe Fahrlässigkeit von Star Ocean zurückzuführen sind.
          </Typography>
          <Typography>12. Star Ocean ist berechtigt, die Chartertour oder einen Teil davon durch eine andere Reederei durch-
            führen zu lassen. Wird ein bestimmter Teil der Tour von einer anderen Reederei durchgeführt, haf-
            tet Star Ocean nicht für Verlust und Beschädigung von Gepäck oder Verspätungen auf diesem Teil

            der Strecke.
          </Typography>
          <Typography> 13. Beschwerden über die Bedingungen an Bord sind an den Kapitän zu richten. Beschwerden über die
            Chartertour sind per E-Mail an Star Ocean zu richten.
          </Typography>
          <Typography> <br /> <b>Preise: </b></Typography>
          <Typography>14. Die Anzahlung beträgt, sofern nicht anders vereinbart ist, 15 % des Preises der Chartertour und
            muss sofort nach der Buchung bezahlt werden. Die Restbetrag ist 4 Wochen vor Abfahrt zu zahlen.

            Die Rechnungen für Anzahlung und Restbetrag sind spätestens bis zu dem auf der Rechnung ange-
            gebenen Fälligkeitsdatum zu zahlen. Mit Entrichtung der Anzahlung ist die Buchung verbindlich.
          </Typography>
          <Typography>15. Unterkünfte sind nicht im Reisepreis inbegriffen, können aber ggf. gegen Aufpreis dazu gebucht
            werden.
          </Typography>
          <Typography><br/><b>Stornierung:</b></Typography>
          <Typography>16. Die Stornogebühr beträgt 25,00 EUR pro Teilnehmer. Im Falle einer Stornierung wird je nach Zeit-
            punkt ein bestimmter Prozentsatz des Reisepreises als Stornierungspauschale erhoben:
          </Typography>
          <Typography> 16.1 Bei einer Stornierung bis 50 Tage vor Abfahrtsdatum 25 % des Reisepreises.
          </Typography>
          <Typography> 16.2 Bei einer Stornierung 49-30 Tage vor Abfahrtsdatum 50 % des Reisepreises.
          </Typography>
          <Typography>16.3 Bei einer Stornierung 29-15 Tage vor Reisebeginn 80 % des Reisepreises.
          </Typography>
          <Typography>16.4 Bei Stornierungen innerhalb von 14 Tagen vor Abfahrtsdatum 100 % des Reisepreises (keine Ermä-
            ßigung). Es wird daher empfohlen, eine Reiseversicherung abzuschließen, damit Sie im Falle einer

            Stornierung der Reise aufgrund von Krankheit, Wetterbedingungen oder anderen Gründen, die eine
            Durchführung der Reise unmöglich machen, abgesichert sind.
          </Typography>
          <Typography> 17. Star Ocean behält sich Preisänderungen vor dem Abfahrtstag vor, wenn sich die bei der Berech-
            nung/Bezahlung des Reisepreises zugrunde gelegten Transportkosten oder Wechselkurse wesent-
            lich ändern. Erhöht sich dadurch der Gesamtpreis der Reise um mehr als 10 %, ist der Teilnehmer

            berechtigt, die Reise zu stornieren und sich den bereits gezahlten Betrag zurückerstatten zu lassen.
            Voraussetzung für die Rückerstattung ist, dass die Stornierung Star Ocean unverzüglich, spätestens
            jedoch 3 Tage nach Benachrichtigung über die Preiserhöhung, mitgeteilt wird.
          </Typography>
          <Typography> <br /> <b>Besondere Umstände:</b> </Typography>
          <Typography>18. Narkotika und andere Drogen sind an Bord nicht erlaubt. Sie dürfen auch keine eigenen Getränke
            mit an Bord nehmen. Ein Verstoß gegen diese Verbote kann zur Absage oder zum Abbruch der

            Chartertour führen. Chartertouren, die wegen eines Verstoßes gegen dieses Verbot ausfallen, wer-
            den nicht erstattet und können nicht umgebucht werden.
          </Typography>
          <Typography> 19. Star Ocean behält sich das Recht vor, die Chartertour wegen höherer Gewalt, Wetter, Betriebsprob-
            lemen, akuter Erkrankungen/Pandemien oder aus Gründen der Teilnehmersicherheit abzusagen.

            Sollte eine solche Situation eintreten, werden die Teilnehmer sofort benachrichtigt, wenn Star
            Ocean von der Situation erfährt.
          </Typography>
          <Typography>20. Wenn Star Ocean die Chartertour aufgrund eines der unter Punkt 19 aufgeführten Ereignisse stor-
            niert, wird den Teilnehmern eine Umbuchung angeboten. Tritt eines der unter Punkt 19 aufgeführ-
            ten Ereignisse nach Beginn der Chartertour ein, entscheidet der Kapitän, ob der nächstgelegene Ha-
            fen aufgesucht werden muss. Eine Umbuchung wird nicht angeboten, wenn mindestens die Hälfte

            der Chartertour absolviert ist.
          </Typography>
          <Typography>21. Star Ocean haftet nicht für infolge einer Stornierung nach Absatz 19 und 20 entstandene Kosten
            der Teilnehmer, und weitere Ansprüche gegenüber Star Ocean sind ausgeschlossen.
          </Typography>
          <Typography> 22. Star Ocean behält sich das Recht vor, Reiseänderungen, insbesondere Änderungen der Ab-
            fahrtszeit, vorzunehmen, falls das infolge plötzlicher externer Ereignisse wie z. B. Wetter- und

            Windstärkeänderungen, Motorschaden, Streiks, Krankheit usw. notwendig ist.
          </Typography>
          <Typography>23. Die Teilnehmer verpflichten sich, sich rechtzeitig vor der Abfahrt auf der M/S Ostsee Star einzu-
            finden. Hat bei verspätetem Eintreffen die Chartertour mit Rücksicht auf andere Passagiere be-
            reits begonnen, entfällt der Anspruch auf Teilnahme und/oder Rückerstattung des Reisepreises.
          </Typography>
          <Typography> 24. Etwaige Landausflüge nach Beginn der Chartertour, zu welchem Zweck auch immer, werden von
            den Teilnehmern auf eigenes Risiko und eigene Verantwortung unternommen.
          </Typography>
          <Typography>25. Die Teilnehmer haften für absichtlich oder unabsichtlich herbeigeführte Sachschäden auf der M/S
            Ostsee Star. Vandalismus, Diebstahl und Körperverletzung werden zur Anzeige gebracht und
            verpflichten zu Schadenersatz.
          </Typography>
          <Typography>26. Der Kapitän hat das Recht, Teilnehmer von der weiteren Teilnahme auszuschließen, wenn sie
            seiner Einschätzung nach aufgrund von Krankheit, Trunkenheit, Drogenkonsum, wiederholten

            Verstößen gegen die Ordnungs- und Sicherheitsregeln an Bord oder aus anderen Gründen un-
            tauglich sind oder andere Teilnehmer stören. In diesem Fall erfolgt keine Rückerstattung und es

            wird keine Umbuchung angeboten.</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}