import React from 'react'
import {render} from "react-dom";
import {App} from "./app";
import {APIProvider} from "./contexts";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

const defaultLanguage = "en-US";

void i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    debug: false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false
    }
  });

const renderApp = () => {
  render((
    <BrowserRouter>
      <Routes>
        <Route path="/" element={(
          <APIProvider>
            <App/>
          </APIProvider>
        )}/>
      </Routes>
    </BrowserRouter>
  ), document.getElementById('root'));
};

renderApp();